






import { Component, Vue } from 'vue-property-decorator'
import TransitionPage from '@/pages/TransitionPage.vue'

@Component({
  components: {
    TransitionPage
  }
})
export default class DefaultView extends Vue {}
